import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { LOCAL_STORAGE_NAME } from "../../utils/constants"
import Api from "../../api/api"

const adminSlice = createSlice({
  name: "adminSlice",
  initialState: JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME)) || {},
  reducers: {
    addAdmin: (state, { payload }) => {
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(payload))
      return payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(payload))
      return payload
    })
  },
})

export const getMe = createAsyncThunk("admin/getMe", async () => {
  try {
    const res = await Api.getMeProfile()
    if (res.status === 200) {
      return res.data.data
    }
  } catch (error) {
    console.log(error)
  }
})

export const { addAdmin } = adminSlice.actions
export default adminSlice.reducer
