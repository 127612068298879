import { useState } from "react"
import Button from "../common/Button"
import Panel from "../layout/Panel/Panel"
import CustomModal from "../layout/modal/modal"

const Doc = () => {
    const [showModal, setShowModal] = useState(false)
    return (
        //panel_wrapper for page
        <Panel>
            {/* c_card for card style with bg, border_radius, padding and shadow */}
            <div className="c_card">
                <p>All content goes here.</p>
                <br />

                {/* Button wrapper with custom margin */}
                <Button margin="0 5px 5px 0">Auto width</Button>

                {/* Button wrapper with custom margin and custom width*/}
                <Button
                    margin="0 5px 5px 0"
                    width="50%"
                >
                    Half width
                </Button>

                {/* Button wrapper with and full width*/}
                <Button width="100%">Full width</Button>

                <br />
                <br />

                {/* custom_modal */}
                <Button onClick={() => setShowModal(true)}>Show Modal</Button>
                {showModal && (
                    <CustomModal
                        title="Modal Title"
                        onHide={() => setShowModal(false)}
                    >
                        All Content will go here
                    </CustomModal>
                )}

                <br />
                <br />

                {/* custom input or select or textarea  */}
                <div className="input_wrapper">
                    <input
                        type="text"
                        placeholder="custom input design"
                    />
                </div>
            </div>
        </Panel>
    )
}

export default Doc
