import React from "react"
import Panel from "../layout/Panel/Panel"
import FoodTruckSection from "../components/food_truck/ft"

const FoodTruck = () => {
  return (
    <Panel>
      <FoodTruckSection />
    </Panel>
  )
}

export default FoodTruck
