import axios from "axios"
import Cookies from "js-cookie"
import { errorHandler } from "../helper/errorhandler"
import { JWT_STORAGE_NAME, LOCAL_STORAGE_NAME } from "../utils/constants"

//axios.defaults.baseURL = "http://localhost:4000/api/v1/"
axios.defaults.baseURL = "https://backend.mealsbus.com/api/v1/"

const getJwt = () => {
  if (
    Cookies.get(JWT_STORAGE_NAME) !== undefined &&
    localStorage.getItem(JWT_STORAGE_NAME)
  ) {
    return Cookies.get(JWT_STORAGE_NAME)
    // return localStorage.getItem(JWT_STORAGE_NAME)
  } else {
    Cookies.remove(JWT_STORAGE_NAME)
    localStorage.removeItem(JWT_STORAGE_NAME)
    localStorage.removeItem(LOCAL_STORAGE_NAME)
  }
}

const setJwt = (token) => {
  Cookies.set(JWT_STORAGE_NAME, token, {
    expires: 2,
    path: "/",
    secure: false,
  })
  localStorage.setItem(JWT_STORAGE_NAME, token)
}

const setAuthHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
  setJwt(token)
}

if (getJwt()) {
  setAuthHeader(getJwt())
}

export default class Api {
  //login
  static login = async (data) => {
    try {
      const res = await axios.post(`admin/login`, data)
      setAuthHeader(res.data.token)
      return res
    } catch (error) {
      localStorage.removeItem(JWT_STORAGE_NAME)
      localStorage.removeItem(LOCAL_STORAGE_NAME)
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //get me profile
  static getMeProfile = async () => {
    try {
      const res = await axios.get(`user/me`)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //update profile
  static updateProfile = async (data) => {
    try {
      const res = await axios.put(`user`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  //update password
  static updatePassword = async (data) => {
    try {
      const res = await axios.put(`user/password/change`, data)
      return res
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // all users
  static allUsers = async () => {
    try {
      const res = await axios.get(`user/all`)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // add user
  static addUser = async (data) => {
    try {
      const res = await axios.post(`admin/add/user`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // delete user
  static deleteUser = async (data) => {
    try {
      const res = await axios.delete(`user/`+data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // update user
  static updateUser = async (data) => {
    try {
      const res = await axios.put(`admin/update/user`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // add restaurant
  static addRestaurant = async (data) => {
    try {
      const res = await axios.post(`admin/add/restaurant`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // all restaurant
  static allRestaurantByType = async (data) => {
    try {
      const res = await axios.get(`restaurant/type/`+data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  static allRestaurant = async () => {
    try {
      const res = await axios.get(`restaurant/all`)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // delete restaurant
  static deleteRestaurant = async (data) => {
    try {
      const res = await axios.delete(`restaurant/`+data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // update restaurant
  static updateRestaurant = async (data) => {
    try {
      const res = await axios.put(`admin/update/restaurant`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // all orders
  static allOrders = async () => {
    try {
      const res = await axios.get(`order/all`)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // delete order
  static deleteOrder = async (data) => {
    try {
      const res = await axios.delete(`order/delete/`+data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // update order
  static updateOrder = async (data) => {
    try {
      const res = await axios.put(`order`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // admin counter
  static adminCounter = async (data) => {
    try {
      const res = await axios.get(`admin/counters/`+data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // update taxes
  static updateTaxes = async (id,data) => {
    try {
      const res = await axios.put(`admin/update/restaurant/taxes/`+id,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // all delivery users
  static allDeliveryUsers = async () => {
    try {
      const res = await axios.get(`delivery/all`)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // update delivery user
  static updatedeliveryPerson = async (data) => {
    try {
      const res = await axios.put(`admin/update/delivery/person`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  // delete delivery user
  static deleteDeliveryUser = async (data) => {
    try {
      const res = await axios.delete(`delivery/`+data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  static addDeliveryUser = async (data) => {
    try {
      const res = await axios.post(`admin/add/delivery/user`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  static allDeliveryCodeUsers = async (data) => {
    try {
      const res = await axios.get(`admin/all/delivery/users/zipcodes`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  static assignDeliveryCodeUsers = async (data) => {
    try {
      const res = await axios.post(`admin/assign/delivery/zipcode`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  static deleteDeliveryCodeUsers = async (data) => {
    try {
      const res = await axios.delete(`admin/delete/delivery/user/code/`+data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }

  static updateDeliveryCodeUsers = async (data) => {
    try {
      const res = await axios.post(`admin/reassign/delivery/zipcode`,data)
      return res.data
    } catch (error) {
      errorHandler(error.response.data)
      return error.response.data
    }
  }
}
