export const LINKS = [
  {
    to: "/",
    title: "Dashboard",
  },
  {
    to: "/users",
    title: "Users",
  },
  {
    to: "/delivery",
    title: "Delivery Users",
  },
  {
    to: "/manage-delivery",
    title: "Manage Delivery",
  },
  {
    to: "/orders",
    title: "Orders",
  },
  {
    to: "/restaurants",
    title: "Restaurants",
  },
  {
    to: "/food-truck",
    title: "Food Truck",
  },
  {
    to: "/home-kitchen",
    title: "Home Kitchen",
  },
  {
    to: "/others",
    title: "Others",
  },
  {
    to: "/seller-commissions",
    title: "Seller Commission",
  },
  {
    to: "/settings",
    title: "Account Settings",
  },
]


