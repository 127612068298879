import "./modal.css"
import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"
import styled from "styled-components"

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 550px;
  height: auto;
  padding: 14px;
  border-radius: var(--border_radius);
  background-color: #ffffff;

  & p {
    margin-bottom:0 !important;
  }

  & .bi::before {
    font-weight: 600 !important;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 340px;
  }
`

const CustomModal = ({ children, title, onHide }) => {
  const modalRef = useRef()
  useEffect(() => {
    let handler = (e) => {
      if (!modalRef.current.contains(e.target)) {
        onHide()
      }
    }

    document.addEventListener("mousedown", handler)
    return () => document.removeEventListener("mousedown", handler)
  }, [onHide])
  return createPortal(
    <>
      <div className="custom_modal_overlay"></div>
      <Modal
        ref={modalRef}
        className="custom_modal_cont"
      >
        <div className="custom_modal_head">
          <p>{title}</p>
          <span onClick={onHide}>
            <i className="bi bi-x-lg"></i>
          </span>
        </div>
        <div className="custom_modal_body">{children}</div>
      </Modal>
    </>,
    document.getElementById("portal"),
  )
}

export default CustomModal
