import OthersSection from '../components/others/others'
import Panel from '../layout/Panel/Panel'

const Others = () => {
  return (
   <Panel>
    <OthersSection/>
   </Panel>
  )
}

export default Others