import React from 'react'
import Panel from '../layout/Panel/Panel'
import HomeKitchenSection from "../components/home_kitchen/hk";


const HomeKitchen = () => {
    return (
        <Panel>
            <HomeKitchenSection/>
        </Panel>
    )
}

export default HomeKitchen