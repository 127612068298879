import React from 'react'
import Panel from '../layout/Panel/Panel'
import RestaurantCommissionSection from "../components/restaurant-commission/rc";



const RestaurantsCommission = () => {
    return (
        <Panel>
            <RestaurantCommissionSection/>
        </Panel>
    )
}

export default RestaurantsCommission