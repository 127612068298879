import Home from "../pages/Home"
import Profile from "../pages/Profile"
import Users from "../pages/Users"
import Orders from "../pages/Orders"
import Restaurants from "../pages/Restaurants"
import Doc from "../pages/Doc"
import FoodTruck from "../pages/FoodTruck"
import HomeKitchen from "../pages/HomeKitchen"
import Others from "../pages/Others"
import Login from "../pages/login"
import RestaurantsCommission from "../pages/Restaurant_Commission";
import DeliveryUser from "../pages/DeliveryUser";
import ManageDeliveryUser from "../pages/ManageDeliveryUser";

export const AUTH_ROUTES = [
  {
    path: "/login",
    component: Login,
  },
]

export const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/settings",
    component: Profile,
  },
  {
    path: "/users",
    component: Users,
  },
  {
    path: "/delivery",
    component: DeliveryUser,
  },
  {
    path: "/orders",
    component: Orders,
  },
  {
    path: "/restaurants",
    component: Restaurants,
  },
  {
    path: "/doc",
    component: Doc,
  },
  {
    path: "/food-truck",
    component: FoodTruck,
  },
  {
    path: "/home-kitchen",
    component: HomeKitchen,
  },
  {
    path: "/others",
    component: Others,
  },
  {
    path: "/seller-commissions",
    component: RestaurantsCommission,
  },
  {
    path: "/manage-delivery",
    component: ManageDeliveryUser,
  },
]
