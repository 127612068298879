import React, {useEffect, useState} from "react"
import { Container, Row, Col } from "react-bootstrap"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import createCache from "@emotion/cache"
import CustomModal from "../../layout/modal/modal"
import Api from "../../api/api";
import {Notifications} from "../../helper/notifications";
import BTN from "../../common/Button";
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function OrderSection() {

  let [orderData,setOrderData]=useState([])

  let [status,setStatus]=useState(null)
  let [updateId,setUpdateId]=useState(null)

  const [addModalShow, setAddModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "500px",
    selectableRows: "none", // toggle checkbox
    onTableChange: (action, state) => {
      console.log(action)
      console.dir(state)
    },
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uid",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (order_status) => {
          const style = {
            padding: "4px 8px",
            fontSize: "12px",
            borderRadius: "5px",
            fontWeight: "bold",
            background:
              order_status === "queue"
                ? "rgba(145, 158, 171, 0.16)"
                : order_status === "pending"
                ? "rgba(255, 171, 0, 0.16)"
                : order_status === "cancelled"
                ? "rgba(255, 86, 48, 0.16)"
                : order_status === "delivered"
                ? "rgba(0, 184, 217, 0.16)"
                : "rgba(54, 179, 126, 0.16)",
            color:
              order_status === "queue"
                ? "rgb(33, 43, 54)"
                : order_status === "pending"
                ? "rgb(183, 110, 0)"
                : order_status === "cancelled"
                ? "rgb(183, 29, 24)"
                : order_status === "delivered"
                ? "rgb(0, 108, 156)"
                : "rgb(27, 128, 106)",
          }
          return <span style={style}> {order_status}</span>
        },
      },
    },
    {
      name: "timeLeft",
      label: "Time Left",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <EditIcon
                className="cGreen pointer"
                onClick={() => handleEdit(tableMeta)}
              />
              <DeleteIcon
                className="cRed pointer"
                onClick={() => handleDelete(tableMeta)}
              />
            </>
          )
        },
      },
    },
  ]

  async function handleEdit(meta) {
    // console.log(meta)
    // console.log(meta.rowData[0])
    setUpdateId(meta.rowData[0])
    setEditModalShow(true)
  }

  async function handleUpdate() {

    let data = {
      id: updateId,
      status: status,
    }

    let res = await Api.updateOrder(data)

    if (res.status == 200)
    {
      refreshRecord()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  async function handleDelete(meta) {
    // console.log(meta)
    // console.log(meta.rowData[0])

    let res = await Api.deleteOrder(meta.rowData[0])

    if (res.status == 200)
    {
      refreshRecord()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }
  }

  useEffect(()=>{
    refreshRecord()
  },[])

  async function refreshRecord() {

    let res = await Api.allOrders();

    if (res.status == 200)
    {
      setOrderData(res.data)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  return (
    <>
      <br />
      <div className="c_card">
        <br />
        <Container fluid>
          <Row>
            <Col
              lg={8}
              md={8}
              xs={8}
            >
              <h2 className="text-left">Orders</h2>
            </Col>
            <Col
              lg={4}
              md={4}
              xs={4}
            >
              {/*<p className="text-right"><BTN margin="0 5px 5px 0" onClick={()=>{setAddModalShow(true)}}>Add User</BTN></p>*/}
            </Col>
          </Row>
        </Container>
        <br />

        <CacheProvider value={muiCache}>
          <ThemeProvider theme={createTheme()}>
            <MUIDataTable
              title={"Orders"}
              data={orderData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>

        {/* Add Model */}
        {addModalShow && (
          <CustomModal
            title="Add"
            onHide={() => setAddModalShow(false)}
          >
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Name"
              />
            </div>
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Email"
              />
            </div>
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Phone"
              />
            </div>
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Address"
              />
            </div>
          </CustomModal>
        )}

        {/* Edit Model */}
        {editModalShow && (
          <CustomModal
            title="Edit"
            onHide={() => setEditModalShow(false)}
          >
            <div className="input_wrapper">
              <select onChange={(e)=>setStatus(e.target.value)}>
                <option >Select Status</option>
                <option value={'queue'}>Queue</option>
                <option value={'pending'}>Pending</option>
                <option value={'cancelled'}>Cancelled</option>
                <option value={'delivered'}>Delivered</option>
              </select>
            </div>
            <p className="text-center">
              <BTN
                  margin="0 5px 5px 0"
                  onClick={() => {
                    handleUpdate();
                    setEditModalShow(false)
                  }}
              >
                Edit
              </BTN>
            </p>
          </CustomModal>
        )}
      </div>
    </>
  )
}

export default OrderSection
