import React from 'react'
import Panel from '../layout/Panel/Panel'
import ManageDeliveryUserSection from "../components/manage_delivery/manage_delivery";




const ManageDeliveryUser = () => {
    return (
        <Panel>
            <ManageDeliveryUserSection/>
        </Panel>
    )
}

export default ManageDeliveryUser