import "./login.css"
import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import ClipLoader from "react-spinners/ClipLoader"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputLabel from "@mui/material/InputLabel"
import InputAdornment from "@mui/material/InputAdornment"
import FormControl from "@mui/material/FormControl"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import Button from "../../common/Button"
import { Notifications } from "../../helper/notifications"
import { LOADER_OVERRIDE, LOCAL_STORAGE_NAME } from "../../utils/constants"
import Api from "../../api/api"
import { addAdmin } from "../../store/slices/adminSlice"
import logo from "../../assets/logo1.png"

function LoginSection() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isDisable, setIsDisable] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [data, setData] = useState({
    name: "",
    email: "",
  })
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => event.preventDefault()

  const inputHandle = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    setIsDisable(true)

    const res = await Api.login(data)
    if (res.status === 200) {
      if (res.data.user.type === "admin") {
        Notifications("success", res.data.message)
        dispatch(addAdmin(res.data.user))
        localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(res.data.user))
        history.push("/")
      } else {
        Notifications("warning", "Unauthorized Account")
      }
    }
    setIsDisable(false)
  }

  return (
    <>
      <div className="loginWrapper">
        <form
          onSubmit={submitHandle}
          className="formWrapper br10"
        >
          <p className="loginLogoWrapper text-center">
            <img
              src={logo}
              alt=""
              className="loginLogoImg"
            />
          </p>
          <br />
          <h2 className="loginHead text-center fw-bold">Admin Login</h2>
          <br />
          <br />
          <Box>
            <TextField
              className="width100P"
              color="warning"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type="email"
              name="email"
              value={data.email}
              onChange={inputHandle}
              required
            />
            <br />
            <br />
            <FormControl
              className="width100P"
              color="warning"
              variant="outlined"
              value={data.password}
              onChange={inputHandle}
              required
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                name="password"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Box>
          <br />
          <p className="text-center">
            <Button disabled={isDisable}>
              {!isDisable ? (
                "Login"
              ) : (
                <ClipLoader
                  color="#FFFFFF"
                  loading={true}
                  cssOverride={LOADER_OVERRIDE}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </p>
        </form>
      </div>
    </>
  )
}

export default LoginSection
