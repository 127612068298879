import React from 'react'
import Panel from '../layout/Panel/Panel'
import RestaurantSection from "../components/restaurants/restaurants";



const Restaurants = () => {
    return (
        <Panel>
            <RestaurantSection/>
        </Panel>
    )
}

export default Restaurants