import React, {useEffect, useState} from "react"
import { Container, Row, Col } from "react-bootstrap"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import createCache from "@emotion/cache"
import CustomModal from "../../layout/modal/modal"
import Api from "../../api/api";
import {Notifications} from "../../helper/notifications";
import BTN from "../../common/Button";
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function DeliveryUserSection() {

  let [deliveryUserData,setDeliveryUserData]=useState([])

  let [name,setName]=useState(null)
  let [email,setEmail]=useState(null)
  let [phone,setPhone]=useState(null)
  let [address,setAddress]=useState(null)
  let [password,setPassword]=useState(null)
  let [city,setCity]=useState(null)
  let [country,setCountry]=useState(null)
  let [status,setStatus]=useState(null)
  let [updateId,setUpdateId]=useState(null)

  const [addModalShow, setAddModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "500px",
    selectableRows: "none", // toggle checkbox
    onTableChange: (action, state) => {
      console.log(action)
      console.dir(state)
    },
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (userStatus) => {
          const style = {
            padding: "4px 8px",
            fontSize: "12px",
            borderRadius: "5px",
            fontWeight: "bold",
            background:
              userStatus == 0 ? "rgba(255, 0, 0, 0.16)" : "rgba(0, 255, 0, 0.16)",

            color:
                userStatus == 0 ? "rgb(255, 0, 0)" : "rgb(60, 179, 113)",
          }
          return <span style={style}> {userStatus == 0? "Inactive": "Active"}</span>
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <EditIcon
                className="cGreen pointer"
                onClick={() => handleEdit(tableMeta)}
              />
              <DeleteIcon
                className="cRed pointer"
                onClick={() => handleDelete(tableMeta)}
              />
            </>
          )
        },
      },
    },
  ]


  async function handleAdd() {

    let data={
      name: name,
      email: email,
      phone: phone,
      address: address,
      password: password,
      city: city,
      country: country,
    }

    let res= await Api.addDeliveryUser(data);

    console.log(res.status)

    if (res.status == 201)
    {
      Notifications('success',res.message)
      refreshRecord()
      resetStateData()
    }

  }

  async function handleEdit(meta) {
    // console.log(meta)
    // console.log(meta.rowData[0])
    setUpdateId(meta.rowData[0])
    setName(meta.rowData[1])
    setEmail(meta.rowData[2])
    setPhone(meta.rowData[3])
    setAddress(meta.rowData[4])
    setCity(meta.rowData[5])
    setCountry(meta.rowData[6])
    setEditModalShow(true)
  }

  async function handleUpdate() {

    let data = {
      id: updateId,
      name: name,
      email: email,
      phone: phone,
      address: address,
      city: city,
      country: country,
      status: status,
    }

    let res = await Api.updatedeliveryPerson(data)

    if (res.status == 200)
    {
      refreshRecord()
      resetStateData()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  async function handleDelete(meta) {
    // console.log(meta)
    // console.log(meta.rowData[0])

    let res = await Api.deleteDeliveryUser(meta.rowData[0])

    if (res.status == 200)
    {
      refreshRecord()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }
  }

  useEffect(()=>{
    refreshRecord()
  },[])

  async function refreshRecord() {

    let res = await Api.allDeliveryUsers();

    if (res.status == 200)
    {
      setDeliveryUserData(res.data)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  function resetStateData() {
    setName("")
    setEmail("")
    setPhone("")
    setAddress("")
    setCity("")
    setCountry("")
    setPassword("")
  }

  return (
    <>
      <br />
      <div className="c_card">
        <br />
        <Container fluid>
          <Row>
            <Col
              lg={8}
              md={8}
              xs={8}
            >
              <h2 className="text-left">Delivery Users</h2>
            </Col>
            <Col
              lg={4}
              md={4}
              xs={4}
            >
              <p className="text-right"><BTN margin="0 5px 5px 0" onClick={()=>{setAddModalShow(true)}}>Add User</BTN></p>
            </Col>
          </Row>
        </Container>
        <br />

        <CacheProvider value={muiCache}>
          <ThemeProvider theme={createTheme()}>
            <MUIDataTable
              // title={"Orders"}
              data={deliveryUserData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>

        {/* Add Model */}
        {addModalShow && (
          <CustomModal
            title="Add"
            onHide={() => setAddModalShow(false)}
          >
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Name"
                value={name}
                  onChange={(e)=>setName(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <input
                type="email"
                placeholder="Email"
                value={email}
                  onChange={(e)=>setEmail(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Phone"
                value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <input
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e)=>setAddress(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e)=>setCity(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  placeholder="Country"
                  value={country}
                  onChange={(e)=>setCountry(e.target.value)}
              />
            </div>
            <p className="text-center">
              <BTN
                  margin="0 5px 5px 0"
                  onClick={() => {
                    handleAdd();
                    setAddModalShow(false)
                  }}
              >
                Add
              </BTN>
            </p>
          </CustomModal>
        )}

        {/* Edit Model */}
        {editModalShow && (
          <CustomModal
            title="Edit"
            onHide={() => setEditModalShow(false)}
          >
            <div className="input_wrapper">
              <input
                  type="text"
                  value={name}
                  onChange={(e)=>setName(e.target.value)}
                  placeholder="Name"
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="email"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  placeholder="Email"
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  value={phone}
                  onChange={(e)=>setPhone(e.target.value)}
                  placeholder="Phone"
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  value={address}
                  onChange={(e)=>setAddress(e.target.value)}
                  placeholder="Address"
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  placeholder="City"
                  value={city}
                  onChange={(e)=>setCity(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  placeholder="Country"
                  value={country}
                  onChange={(e)=>setCountry(e.target.value)}
              />
            </div>
            <div className="input_wrapper">
              <select onChange={(e)=>setStatus(e.target.value)}>
                <option >Select Status</option>
                <option value={'0'}>Inactive</option>
                <option value={'1'}>Active</option>
              </select>
            </div>
            <p className="text-center">
              <BTN
                  margin="0 5px 5px 0"
                  onClick={() => {
                    handleUpdate();
                    setEditModalShow(false)
                  }}
              >
                Edit
              </BTN>
            </p>
          </CustomModal>
        )}
      </div>
    </>
  )
}

export default DeliveryUserSection
