import React, {useEffect, useState} from "react"
import "./users.css"
import { Container, Row, Col } from "react-bootstrap"
import BTN from "../../common/Button"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import createCache from "@emotion/cache"
import CustomModal from "../../layout/modal/modal"
import Api from "../../api/api";
import { Notifications } from "../../helper/notifications";
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function UsersSection() {

  let [userData,setUserData]=useState([])

  let [name,setName]=useState(null)
  let [email,setEmail]=useState(null)
  let [phone,setPhone]=useState(null)
  let [address,setAddress]=useState(null)
  let [password,setPassword]=useState(null)
  let [city,setCity]=useState(null)
  let [country,setCountry]=useState(null)

  let [updateId,setUpdateId]=useState(null)

  const [addModalShow, setAddModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "500px",
    selectableRows: "none", // toggle checkbox
    onTableChange: (action, state) => {
      // console.log(action)
      // console.dir(state)
    },
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "city",
      label: "City",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
              <>
                <EditIcon
                    className="cGreen pointer"
                    onClick={() => handleEdit(tableMeta)}
                />
                <DeleteIcon
                    className="cRed pointer"
                    onClick={() => handleDelete(tableMeta)}
                />
              </>
          )
        },
      },
    },
  ]

  async function handleAdd() {

    let data={
      name: name,
      email: email,
      phone: phone,
      address: address,
      password: password,
      city: city,
      country: country,
      role: "1",
      type: "user",
      status: true,
      isVerified: true
    }

    let res= await Api.addUser(data);

    console.log(res.status)

    if (res.status == 201)
    {
      Notifications('success',res.message)
      refreshRecord()
      resetForm()
    }

  }

  async function handleEdit(meta) {
    // console.log(meta)
    // console.log(meta.rowData[0])
    resetForm()
    setUpdateId(meta.rowData[0])
    setName(meta.rowData[1])
    setEmail(meta.rowData[2])
    setPhone(meta.rowData[3])
    setAddress(meta.rowData[4])
    setCity(meta.rowData[5])
    setCountry(meta.rowData[6])
    setEditModalShow(true)
  }

  async function handleUpdate() {

    let data = {
      id: updateId,
      name: name,
      email: email,
      phone: phone,
      address: address,
      city: city,
      country: country,
    }

    let res = await Api.updateUser(data)

    if (res.status == 200)
    {
      refreshRecord()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  async function handleDelete(meta) {
    // console.log(meta)
    // console.log(meta.rowData[0])

    let res = await Api.deleteUser(meta.rowData[0])

    if (res.status == 200)
    {
      refreshRecord()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }
  }

  function resetForm() {
    setName("")
    setEmail("")
    setPhone("")
    setAddress("")
    setPassword("")
    setCity("")
    setCountry("")
  }

  useEffect(()=>{
    refreshRecord()
  },[])

  async function refreshRecord() {

    let res = await Api.allUsers();

    if (res.status == 200)
    {
      setUserData(res.data)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  return (
      <>
        <br />
        <div className="c_card">
          <br />
          <Container fluid>
            <Row>
              <Col
                  lg={8}
                  md={8}
                  xs={8}
              >
                <h2 className="text-left">Users</h2>
              </Col>
              <Col
                  lg={4}
                  md={4}
                  xs={4}
              >
                <p className="text-right">
                  <BTN
                      margin="0 5px 5px 0"
                      onClick={() => {
                        setAddModalShow(true)
                      }}
                  >
                    Add
                  </BTN>
                </p>
              </Col>
            </Row>
          </Container>
          <br />

          <CacheProvider value={muiCache}>
            <ThemeProvider theme={createTheme()}>
              <MUIDataTable
                  title={""}
                  data={userData}
                  columns={columns}
                  options={options}
              />
            </ThemeProvider>
          </CacheProvider>

          {/* Add Model */}
          {addModalShow && (
              <CustomModal
                  title="Add"
                  onHide={() => setAddModalShow(false)}
              >
                <div className="input_wrapper">
                  <input
                      type="text"
                      placeholder="Name"
                      onChange={(e)=>setName(e.target.value)}
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="email"
                      placeholder="Email"
                      onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="password"
                      placeholder="Password"
                      onChange={(e)=>setPassword(e.target.value)}
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      placeholder="Phone"
                      onChange={(e)=>setPhone(e.target.value)}
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      placeholder="Address"
                      onChange={(e)=>setAddress(e.target.value)}
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      placeholder="City"
                      onChange={(e)=>setCity(e.target.value)}
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      placeholder="Country"
                      onChange={(e)=>setCountry(e.target.value)}
                  />
                </div>
                <p className="text-center">
                  <BTN
                      margin="0 5px 5px 0"
                      onClick={() => {
                        handleAdd();
                        setAddModalShow(false)
                      }}
                  >
                    Add
                  </BTN>
                </p>
              </CustomModal>
          )}

          {/* Edit Model */}
          {editModalShow && (
              <CustomModal
                  title="Edit"
                  onHide={() => setEditModalShow(false)}
              >
                <div className="input_wrapper">
                  <input
                      type="text"
                      value={name}
                      onChange={(e)=>setName(e.target.value)}
                      placeholder="Name"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      value={email}
                      onChange={(e)=>setEmail(e.target.value)}
                      placeholder="Email"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      value={phone}
                      onChange={(e)=>setPhone(e.target.value)}
                      placeholder="Phone"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      value={address}
                      onChange={(e)=>setAddress(e.target.value)}
                      placeholder="Address"
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      placeholder="City"
                      value={city}
                      onChange={(e)=>setCity(e.target.value)}
                  />
                </div>
                <div className="input_wrapper">
                  <input
                      type="text"
                      placeholder="Country"
                      value={country}
                      onChange={(e)=>setCountry(e.target.value)}
                  />
                </div>
                <p className="text-center">
                  <BTN
                      margin="0 5px 5px 0"
                      onClick={() => {
                        handleUpdate();
                        setEditModalShow(false)
                      }}
                  >
                    Edit
                  </BTN>
                </p>
              </CustomModal>
          )}
        </div>
      </>
  )
}

export default UsersSection
