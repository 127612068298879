import "./Sidebar.css"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import logo from "../../assets/logo.svg"
import { LINKS } from "../../utils/links"

const Sidebar = () => {
  const toggleSidebar = useSelector((state) => state.sidebar)
  const activeLink = (id) => {
    if (id === window.location.pathname) return "active"
  }

  return (
    <div
      className="sidebar"
      style={{ left: toggleSidebar ? "1rem" : "-280px" }}
    >
      <div className="c_card">
        <div
          className="logo"
          style={{ margin: "0 auto 10px auto" }}
        >
          <img
            src={logo}
            alt="Mealsbus Logo"
          />
        </div>
        <div className="sidebar_cont">
          {LINKS.map(({ to, title }) => (
            <Link
              to={to}
              className={`link ${activeLink(to)}`}
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Sidebar
