import React, {useEffect, useState} from "react"
import { Container, Row, Col } from "react-bootstrap"
import BTN from "../../common/Button"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import createCache from "@emotion/cache"
import CustomModal from "../../layout/modal/modal"
import Api from "../../api/api";
import {Notifications} from "../../helper/notifications";
const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
})

function RestaurantCommissionSection() {

    let [restaurantData,setRestaurentData]=useState([])

    let [mc,setMC]=useState(null)
    let [dc,setDC]=useState(null)
    let [pf,setPF]=useState(null)
    let [st,setST]=useState(null)
    let [oc,setOC]=useState(null)

    let [updateId,setUpdateId]=useState(null)

    const [addModalShow, setAddModalShow] = useState(false)
    const [editModalShow, setEditModalShow] = useState(false)

    const options = {
        search: true,
        download: true,
        print: true,
        viewColumns: true,
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        tableBodyHeight: "500px",
        selectableRows: "none",
        onTableChange: (action, state) => {
            // console.log(action)
            // console.dir(state)
        },
    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "storeName",
            label: "Store Name",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "marketingCommission",
            label: "Marketing Commission",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "deliveryCommission",
            label: "Delivery Commission",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "processingFee",
            label: "Processing Fee",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "salesTax",
            label: "Sales Tax",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "otherCharges",
            label: "Other Charges",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <EditIcon
                                className="cGreen pointer"
                                onClick={() => handleEdit(tableMeta)}
                            />
                            {/*<DeleteIcon*/}
                            {/*    className="cRed pointer"*/}
                            {/*    onClick={() => handleDelete(tableMeta)}*/}
                            {/*/>*/}
                        </>
                    )
                },
            },
        },
    ]

    // async function handleAdd() {
    //
    //     let data={
    //         storeName: name,
    //         email: email,
    //         phone: phone,
    //         address: address,
    //         password: password,
    //         city: city,
    //         country: country,
    //         zipcode: zipcode,
    //         state: state,
    //         businessType: "restaurant",
    //         explainBusiness: "",
    //         status: true,
    //         isVerified: true,
    //     }
    //
    //     let res= await Api.addRestaurant(data);
    //
    //     console.log(res.status)
    //
    //     if (res.status == 201)
    //     {
    //         Notifications('success',res.message)
    //         refreshRecord()
    //         resetForm()
    //     }
    //
    // }

    async function handleEdit(meta) {
        // console.log(meta)
        // console.log(meta.rowData[0])
        resetForm()
        setUpdateId(meta.rowData[0])
        setMC(meta.rowData[2])
        setDC(meta.rowData[3])
        setPF(meta.rowData[4])
        setOC(meta.rowData[6])
        setST(meta.rowData[5])
        setEditModalShow(true)
    }

    async function handleUpdate() {

        let data = {
            marketingCommission: mc,
            deliveryCommission: dc,
            processingFee: pf,
            salesTax: st,
            otherCharges: oc,
        }

        let res = await Api.updateTaxes(updateId,data)

        if (res.status == 200)
        {
            refreshRecord()
            Notifications('success', res.message)
        }
        else
        {
            Notifications('error', res.message)
        }

    }

    // async function handleDelete(meta) {
    //     // console.log(meta)
    //     // console.log(meta.rowData[0])
    //
    //     let res = await Api.deleteRestaurant(meta.rowData[0])
    //
    //     if (res.status == 200)
    //     {
    //         refreshRecord()
    //         Notifications('success', res.message)
    //     }
    //     else
    //     {
    //         Notifications('error', res.message)
    //     }
    // }

    function resetForm() {
        setMC("")
        setDC("")
        setPF("")
        setOC("")
        setST("")
    }

    useEffect(()=>{
        refreshRecord()
    },[])

    async function refreshRecord() {

        let res = await Api.allRestaurant();

        if (res.status == 200)
        {
            setRestaurentData(res.data)
        }
        else
        {
            Notifications('error', res.message)
        }

    }


    return (
        <>
            <br />
            <div className="c_card">
                <br />
                <Container fluid>
                    <Row>
                        <Col
                            lg={8}
                            md={8}
                            xs={8}
                        >
                            <h2 className="text-left">Restaurants Commission</h2>
                        </Col>
                        <Col
                            lg={4}
                            md={4}
                            xs={4}
                        >
                            <p className="text-right">
                                <BTN
                                    margin="0 5px 5px 0"
                                    onClick={() => {
                                        setAddModalShow(true)
                                    }}
                                >
                                    Add
                                </BTN>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <br />

                <CacheProvider value={muiCache}>
                    <ThemeProvider theme={createTheme()}>
                        <MUIDataTable
                            title={""}
                            data={restaurantData}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </CacheProvider>

                {/* Add Model */}
                {/*{addModalShow && (*/}
                {/*    <CustomModal*/}
                {/*        title="Add"*/}
                {/*        onHide={() => setAddModalShow(false)}*/}
                {/*    >*/}
                {/*        <Container fluid>*/}
                {/*            <Row>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            placeholder="Store Name"*/}
                {/*                            onChange={(e)=>setName(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="email"*/}
                {/*                            placeholder="Email"*/}
                {/*                            onChange={(e)=>setEmail(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Container>*/}

                {/*        <Container fluid>*/}
                {/*            <Row>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="password"*/}
                {/*                            placeholder="Password"*/}
                {/*                            onChange={(e)=>setPassword(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="tel"*/}
                {/*                            placeholder="Phone"*/}
                {/*                            onChange={(e)=>setPhone(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Container>*/}

                {/*        <Container fluid>*/}
                {/*            <Row>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            placeholder="Address"*/}
                {/*                            onChange={(e)=>setAddress(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            placeholder="City"*/}
                {/*                            onChange={(e)=>setCity(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Container>*/}

                {/*        <Container fluid>*/}
                {/*            <Row>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            placeholder="Country"*/}
                {/*                            onChange={(e)=>setCountry(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="text"*/}
                {/*                            placeholder="State"*/}
                {/*                            onChange={(e)=>setState(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Container>*/}
                {/*        <Container fluid>*/}
                {/*            <Row>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}
                {/*                    <div className="input_wrapper">*/}
                {/*                        <input*/}
                {/*                            type="number"*/}
                {/*                            placeholder="Zip Code"*/}
                {/*                            onChange={(e)=>setZipcode(e.target.value)}*/}
                {/*                        />*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*                <Col lg={6} md={6} xs={6}>*/}

                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Container>*/}
                {/*        <p className="text-center">*/}
                {/*            <BTN*/}
                {/*                margin="0 5px 5px 0"*/}
                {/*                onClick={() => {*/}
                {/*                    // handleAdd();*/}
                {/*                    setAddModalShow(false)*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                Add*/}
                {/*            </BTN>*/}
                {/*        </p>*/}
                {/*    </CustomModal>*/}
                {/*)}*/}

                {/* Edit Model */}
                {editModalShow && (
                    <CustomModal
                        title="Edit"
                        onHide={() => setEditModalShow(false)}
                    >
                        <Container fluid>
                            <Row>
                                <Col lg={6} md={6} xs={6}>
                                    <div className="input_wrapper">
                                        <input
                                            type="number"
                                            placeholder="Marketing Commission"
                                            value={mc}
                                            onChange={(e)=>setMC(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <div className="input_wrapper">
                                        <input
                                            type="number"
                                            placeholder="Delivery Commission"
                                            value={dc}
                                            onChange={(e)=>setDC(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid>
                            <Row>
                                <Col lg={6} md={6} xs={6}>
                                    <div className="input_wrapper">
                                        <input
                                            type="number"
                                            placeholder="Processing Fee"
                                            value={pf}
                                            onChange={(e)=>setPF(e.target.value)}
                                        />
                                    </div>
                                </Col>
                                <Col lg={6} md={6} xs={6}>
                                    <div className="input_wrapper">
                                        <input
                                            type="text"
                                            placeholder="Sales Tax"
                                            value={st}
                                            onChange={(e)=>setST(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid>
                            <Row>
                                <Col lg={6} md={6} xs={6}>
                                    <div className="input_wrapper">
                                        <input
                                            type="text"
                                            placeholder="Other Charges"
                                            value={oc}
                                            onChange={(e)=>setOC(e.target.value)}
                                        />
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                        <p className="text-center">
                            <BTN
                                margin="0 5px 5px 0"
                                onClick={() => {
                                    handleUpdate();
                                    setEditModalShow(false)
                                }}
                            >
                                Edit
                            </BTN>
                        </p>
                    </CustomModal>
                )}
            </div>
        </>
    )
}

export default RestaurantCommissionSection
