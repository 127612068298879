import React, {useEffect, useState} from "react"
import { Container, Row, Col } from "react-bootstrap"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import MUIDataTable from "mui-datatables"
import { CacheProvider } from "@emotion/react"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import createCache from "@emotion/cache"
import CustomModal from "../../layout/modal/modal"
import Api from "../../api/api";
import {Notifications} from "../../helper/notifications";
import BTN from "../../common/Button";
const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
})

function ManageDeliveryUserSection() {

  let [deliveryUserData,setDeliveryUserData]=useState([])
  let [userData,setUserData]=useState([])

  let [userID,setUserId]=useState(null)
  let [zipcode,setZipCode]=useState(null)

  const [addModalShow, setAddModalShow] = useState(false)
  const [editModalShow, setEditModalShow] = useState(false)
  let [updateId,setUpdateId]=useState(null)

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "500px",
    selectableRows: "none", // toggle checkbox
    onTableChange: (action, state) => {
      console.log(action)
      console.dir(state)
    },
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uid",
      label: "User Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (user) => {
          return <span>{user?.name}</span>
        },
      },
    },
    {
      name: "zipcode",
      label: "Zipcode",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <EditIcon
                className="cGreen pointer"
                onClick={() => handleEdit(tableMeta)}
              />
              <DeleteIcon
                className="cRed pointer"
                onClick={() => handleDelete(tableMeta)}
              />
            </>
          )
        },
      },
    },
  ]


  async function handleAdd() {

    let data={
      id: userID,
      zipcode: zipcode,
    }

    let res= await Api.assignDeliveryCodeUsers(data);

    console.log(res.status)

    if (res.status == 201)
    {
      Notifications('success',res.message)
      refreshRecord()
      resetStateData()
    }

  }

  async function handleEdit(meta) {
    // console.log(meta)
    // console.log(meta.rowData[0])
    setUpdateId(meta.rowData[0])
    setZipCode(meta.rowData[3])
    setEditModalShow(true)
  }

  async function handleUpdate() {

    let data = {
      id: updateId,
      uid: userID,
      zipcode: zipcode,
    }

    let res = await Api.updateDeliveryCodeUsers(data)

    if (res.status == 200)
    {
      refreshRecord()
      resetStateData()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  async function handleDelete(meta) {

    let res = await Api.deleteDeliveryCodeUsers(meta.rowData[0])

    if (res.status == 200)
    {
      refreshRecord()
      Notifications('success', res.message)
    }
    else
    {
      Notifications('error', res.message)
    }
  }

  useEffect(()=>{
    refreshRecord()
    getAllUsers()
  },[])

  async function refreshRecord() {

    let res = await Api.allDeliveryCodeUsers();

    if (res.status == 200)
    {
      setDeliveryUserData(res.data)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  async function getAllUsers() {

    let res = await Api.allDeliveryUsers();

    if (res.status == 200)
    {
      setUserData(res.data)
    }
    else
    {
      Notifications('error', res.message)
    }

  }

  function resetStateData() {
    setZipCode("")
    setUserId("")
  }

  return (
    <>
      <br />
      <div className="c_card">
        <br />
        <Container fluid>
          <Row>
            <Col
              lg={8}
              md={8}
              xs={8}
            >
              <h2 className="text-left">Manage Delivery Users</h2>
            </Col>
            <Col
              lg={4}
              md={4}
              xs={4}
            >
              <p className="text-right"><BTN margin="0 5px 5px 0" onClick={()=>{setAddModalShow(true)}}>Add Code</BTN></p>
            </Col>
          </Row>
        </Container>
        <br />

        <CacheProvider value={muiCache}>
          <ThemeProvider theme={createTheme()}>
            <MUIDataTable
              // title={"Orders"}
              data={deliveryUserData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </CacheProvider>

        {/* Add Model */}
        {addModalShow && (
          <CustomModal
            title="Add"
            onHide={() => setAddModalShow(false)}
          >
            <div className="input_wrapper">
              <select onChange={(e)=>setUserId(e.target.value)}>
                <option >Select User</option>
                {
                  userData?.map((itm,i)=>(
                      <option value={itm.id}>{itm.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  placeholder="Zipcode"
                  value={zipcode}
                  onChange={(e)=>setZipCode(e.target.value)}
              />
            </div>
            <p className="text-center">
              <BTN
                  margin="0 5px 5px 0"
                  onClick={() => {
                    handleAdd();
                    setAddModalShow(false)
                  }}
              >
                Add
              </BTN>
            </p>
          </CustomModal>
        )}

        {/* Edit Model */}
        {editModalShow && (
          <CustomModal
            title="Edit"
            onHide={() => setEditModalShow(false)}
          >
            <div className="input_wrapper">
              <select onChange={(e)=>setUserId(e.target.value)}>
                <option >Select Status</option>
                {
                  userData?.map((itm,i)=>(
                      <option value={itm.id}>{itm.name}</option>
                  ))
                }
              </select>
            </div>
            <div className="input_wrapper">
              <input
                  type="text"
                  placeholder="Zipcode"
                  value={zipcode}
                  onChange={(e)=>setZipCode(e.target.value)}
              />
            </div>
            <p className="text-center">
              <BTN
                  margin="0 5px 5px 0"
                  onClick={() => {
                    handleUpdate();
                    setEditModalShow(false)
                  }}
              >
                Edit
              </BTN>
            </p>
          </CustomModal>
        )}
      </div>
    </>
  )
}

export default ManageDeliveryUserSection
