import React from 'react'
import Panel from '../layout/Panel/Panel'
import UsersSection from "../components/users/users";


const Users = () => {
    return (
        <Panel>
            <UsersSection/>
        </Panel>
    )
}

export default Users