import React from 'react'
import Panel from '../layout/Panel/Panel'
import OrderSection from "../components/orders/orders";



const Orders = () => {
    return (
        <Panel>
            <OrderSection/>
        </Panel>
    )
}

export default Orders