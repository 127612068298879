import "./profile.css"
import { useLayoutEffect, useState } from "react"
import Button from "../../common/Button"
import Loader from "../../components/loader/loader"
import camera from "../../assets/icons/camera.svg"
import Api from "../../api/api"
import ClipLoader from "react-spinners/ClipLoader"
import { LOADER_OVERRIDE } from "../../utils/constants"
import { Notifications } from "../../helper/notifications"

const Profile = () => {
  const [isLoader, setIsLoader] = useState(true)
  const [isDisable1, setIsDisable1] = useState(false)
  const [isDisable2, setIsDisable2] = useState(false)
  const [data, setData] = useState({
    avatar: "",
    avatarURL: "",
    name: "",
    email: "",
  })
  const [data2, setData2] = useState({
    prePassword: "",
    newPassword: "",
    confirmPassword: "",
  })

  const submitHandle1 = async (e) => {
    e.preventDefault()
    setIsDisable1(true)

    const res = await Api.updateProfile(data)
    if (res.status == 200) {
      window.location.reload()
    }
    setIsDisable1(false)
  }

  const submitHandle2 = async (e) => {
    e.preventDefault()
    if (data2.newPassword !== data2.confirmPassword)
      return Notifications("warning", "Confirm doesn't match")
    setIsDisable2(true)
    const res = await Api.updatePassword(data2)
    if (res.status == 200) {
      Notifications("success", "Password updated")
      setData2({ newPassword: "", confirmPassword: "", prePassword: "" })
    }
    setIsDisable2(false)
  }

  const setImageHandle = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setData((prev) => ({
          ...prev,
          avatar: event.target.result,
          avatarURL: event.target.result,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const inputHandle = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const inputHandle2 = (e) => {
    setData2((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const getMe = async () => {
    const res = await Api.getMeProfile()
    if (res.status === 200) {
      setData({
        avatar: "",
        avatarURL: res.data.data.avatar,
        name: res.data.data.name,
        email: res.data.data.email,
      })
    }
    setIsLoader(false)
  }

  useLayoutEffect(() => {
    getMe()
  }, [])

  return (
    <>
      {isLoader && <Loader />}
      <div className="profile_page_cont">
        <div className="c_card">
          <div className="title_3">Profile Setting</div>
          <br />
          <form
            onSubmit={submitHandle1}
            className="profile_form"
          >
            <Picture
              image={data.avatarURL}
              setImageHandle={setImageHandle}
            />
            <br />
            <div className="profile_input_wrapper">
              <div className="input_wrapper">
                <input
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={inputHandle}
                  placeholder="Name"
                  required
                />
              </div>
              <div className="input_wrapper">
                <input
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={inputHandle}
                  placeholder="Email"
                  disabled
                  readOnly
                />
              </div>
            </div>
            <Button disabled={isDisable1}>
              {!isDisable1 ? (
                "Update"
              ) : (
                <ClipLoader
                  color="#FFFFFF"
                  loading={true}
                  cssOverride={LOADER_OVERRIDE}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </form>
          <br />
          <form
            onSubmit={submitHandle2}
            className="profile_form"
          >
            <div className="profile_input_wrapper">
              <div className="input_wrapper">
                <input
                  type="password"
                  name="prePassword"
                  value={data2.prePassword}
                  onChange={inputHandle2}
                  placeholder="Current Password"
                  required
                />
              </div>
            </div>
            <div className="profile_input_wrapper">
              <div className="input_wrapper">
                <input
                  type="password"
                  name="newPassword"
                  value={data2.newPassword}
                  onChange={inputHandle2}
                  placeholder="New Password"
                  required
                />
              </div>
            </div>
            <div className="profile_input_wrapper">
              <div className="input_wrapper">
                <input
                  type="password"
                  name="confirmPassword"
                  value={data2.confirmPassword}
                  onChange={inputHandle2}
                  placeholder="Confirm Password"
                  required
                />
              </div>
            </div>
            <Button disabled={isDisable2}>
              {!isDisable2 ? (
                "Change Password"
              ) : (
                <ClipLoader
                  color="#FFFFFF"
                  loading={true}
                  cssOverride={LOADER_OVERRIDE}
                  size={25}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              )}
            </Button>
          </form>
        </div>
      </div>
    </>
  )
}

const Picture = ({ image, setImageHandle }) => {
  return (
    <div className="profile_picture">
      <label htmlFor="avatar">
        <img
          src={image}
          alt="avatar"
        />
        <span>
          <img
            src={camera}
            alt=""
          />
        </span>
      </label>
      <input
        type="file"
        id="avatar"
        name="avatar"
        accept="image/*"
        onChange={setImageHandle}
      />
    </div>
  )
}

export default Profile
