import React from 'react'
import Panel from '../layout/Panel/Panel'
import DeliveryUserSection from "../components/delivery_person/delivery";



const DeliveryUser = () => {
    return (
        <Panel>
            <DeliveryUserSection/>
        </Panel>
    )
}

export default DeliveryUser