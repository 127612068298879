import { configureStore } from "@reduxjs/toolkit"
import sidebarSlice from "./slices/sidebarSlice"
import adminSlice from "./slices/adminSlice"

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    admin: adminSlice,
  },
})
